import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Socials.module.scss"

function Socials() {
  return (
    <section>
      <Container>
        <div className={styles.socialHeadig}>
          <p>Stay Connected</p>
        </div>
        <div>
          <Row className="gap-30">
            <Col xl={2} lg={4} md={6} xs={6}>
              <a
                type="button"
                href="https://www.facebook.com/InvoZone1/?_rdc=2&amp;_rdr"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Like us on Facebook"
                className={styles.buttons}
              >
                <div>
                  <StaticImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    alt="facebook"
                    src={"../../images/socialIcons/facebook.svg"}
                  />
                </div>
                <div className={styles.butonsContent}>
                  6.1k <br />
                  <span className={styles.butonsContent2}>Likes</span>
                </div>
              </a>
            </Col>
            <Col xl={2} lg={4} md={6} xs={6}>
              <a
                type="button"
                className={styles.buttons}
                href="https://www.instagram.com/invozone/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Follow us on Instagram"
                style={{ background: "#C23785" }}
              >
                <div>
                  <StaticImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    alt="insta"
                    src={"../../images/socialIcons/insta.svg"}
                  />
                </div>
                <div className={styles.butonsContent}>
                  2K <br />
                  <span className={styles.butonsContent2}>Followers</span>
                </div>
              </a>
            </Col>
            <Col xl={2} lg={4} md={6} xs={6}>
              <a
                type="button"
                className={styles.buttons}
                href="https://www.youtube.com/channel/UCamPgA6MxGvg2T38QY1wOSQ"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Subscribe us on YouTube"
                style={{ background: "#EF5043" }}
              >
                <div>
                  <StaticImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    alt="youtube"
                    src={"../../images/socialIcons/youtube.svg"}
                  />
                </div>
                <div className={styles.butonsContent}>
                  1.4K <br />
                  <span className={styles.butonsContent2}>Subscribers</span>
                </div>
              </a>
            </Col>
            <Col xl={2} lg={4} md={6} xs={6}>
              <a
                type="button"
                href="https://twitter.com/invozone?lang=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Follow us on Twitter"
                className={styles.buttons}
                style={{ background: "#42C0F5" }}
              >
                <div>
                  <StaticImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    alt="twitter"
                    src={"../../images/react-icons/twitter white logo.svg"}
                    style={{ height: "22px", width: "22px" }}
                  />
                </div>
                <div className={styles.butonsContent}>
                  1K <br />
                  <span className={styles.butonsContent2}>Followers</span>
                </div>
              </a>
            </Col>
            <Col xl={2} lg={4} md={6} xs={6}>
              <a
                type="button"
                className={styles.buttons}
                style={{ background: "#1B7BFD" }}
                href="https://www.linkedin.com/company/invozone"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Follow us on LinkedIn"
              >
                <div>
                  <StaticImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    alt="linkedin"
                    src={"../../images/socialIcons/be.svg"}
                  />
                </div>
                <div className={styles.butonsContent}>
                  91K <br />
                  <span className={styles.butonsContent2}>Followers</span>
                </div>
              </a>
            </Col>
            <Col xl={2} lg={4} md={6} xs={6}>
              <a
                type="button"
                href="https://www.tiktok.com/@invozone"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Follow us on GitHub"
                className={styles.buttons}
                style={{ background: "#050a1f" }}
              >
                <div>
                  <StaticImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    alt="github"
                    src={"../../images/socialIcons/tiktok.svg"}
                  />
                </div>
                <div className={styles.butonsContent}>
                  50+ <br />
                  <span className={styles.butonsContent2}>Followers</span>
                </div>
              </a>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default Socials
