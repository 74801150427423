// extracted by mini-css-extract-plugin
export var Maincard = "MainCard-module--Maincard--87598";
export var authurName = "MainCard-module--authurName--f896b";
export var avatar = "MainCard-module--avatar--3eef9";
export var boder = "MainCard-module--boder--12389";
export var body = "MainCard-module--body--4460d";
export var border = "MainCard-module--border--a09e8";
export var example = "MainCard-module--example--cf0d6";
export var image = "MainCard-module--image--6632d";
export var imagebox = "MainCard-module--imagebox--d2f7a";
export var readTime = "MainCard-module--readTime--8af0e";
export var topSection = "MainCard-module--topSection--4830f";