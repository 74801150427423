import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import leftArr from "../../images/svg/leftArr.svg"
import rightArr from "../../images/svg/rightArr.svg"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import MainCard from "../common/new-blog/MainCard"
import PostCard from "../common/PostCard"
import * as styles from "./BlogShow.module.scss"
import "./BlogShow.scss"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          src={rightArr}
          onClick={onClick}
          className={styles.portfolioArrowIcon}
          alt="arrow"
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img src={leftArr} className={styles.portfolioArrowIcon} alt="arrow" />
      </div>
    </div>
  )
}

const BlogShow = ({ categories, postsData, handleShowBlogs }) => {
  const settings = {
    dots: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Container className={styles.BlogShow}>
      <div className={styles.heading}>
        <Row className="align-items-center gap-30">
          <Col md={12} lg={5}>
            <h2>
              Directory of <span className="text-primary"> Trending Posts</span>
            </h2>
          </Col>
          <Col md={12} lg={7}>
            <div className={`${styles.slider} sliderBlogShow`}>
              <Slider {...settings}>
                {categories?.map(({ slug, name }, i) => {
                  return (
                    <Link to={`/blog/category/${slug}/`} key={i}>
                      {name}
                    </Link>
                  )
                })}
              </Slider>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="mt-5 ">
        {postsData?.length > 0 &&
          postsData?.slice(1, 9)?.map((post, i) => {
            if (i === 4) {
              return (
                <React.Fragment>
                  {/* <Col
                    xl={4}
                    lg={6}
                    md={6}
                    xs={12}
                    style={{ display: "flex", padding: 10 }}
                  >
                    <SubscriptionBox />
                  </Col> */}
                  <Col xl={8} md={12} style={{ padding: 10 }}>
                    <MainCard
                      styleBody={{ maxWidth: "658px", minHeight: "230px" }}
                      styleCard={{ minHeight: "554px", maxWidth: "100%" }}
                      posts={post}
                    />
                  </Col>
                </React.Fragment>
              )
            } else
              return (
                <Col
                  md={6}
                  lg={6}
                  xl={4}
                  key={i}
                  style={{ padding: 10, display: "flex" }}
                >
                  <PostCard post={post} />
                </Col>
              )
          })}
      </Row>
      <div className={styles.Blogbutton}>
        <button onClick={handleShowBlogs} className="btn_white_border">
          Discover more
        </button>
      </div>
    </Container>
  )
}

export default BlogShow
