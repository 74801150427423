// extracted by mini-css-extract-plugin
export var authurName = "Banner-module--authurName--828d5";
export var avatar = "Banner-module--avatar--d902e";
export var backgroundblur = "Banner-module--backgroundblur--3eeb4";
export var banner = "Banner-module--banner--cfe02";
export var boder = "Banner-module--boder--84e44";
export var body = "Banner-module--body--ec36a";
export var border = "Banner-module--border--d74ac";
export var btnRound = "Banner-module--btnRound--aae79";
export var card = "Banner-module--card--d1c58";
export var crossIcon = "Banner-module--crossIcon--94268";
export var example = "Banner-module--example--d1dcc";
export var formControl = "Banner-module--form-control--f2701";
export var headPop = "Banner-module--headPop--ada13";
export var heading = "Banner-module--heading--9fb4f";
export var image = "Banner-module--image--9a4d6";
export var imagebox = "Banner-module--imagebox--d8eee";
export var info = "Banner-module--info--3e922";
export var innerText = "Banner-module--innerText--d935d";
export var most = "Banner-module--most--4733d";
export var popularContainer = "Banner-module--popularContainer--a4de4";
export var popularImage = "Banner-module--popularImage--0ed41";
export var popularImageBox = "Banner-module--popularImageBox--7ea67";
export var popularItem = "Banner-module--popularItem--8515c";
export var popularSection = "Banner-module--popularSection--9c645";
export var readTime = "Banner-module--readTime--2a288";
export var rightBox = "Banner-module--rightBox--e1889";
export var round = "Banner-module--round--46d4b";
export var searchInput = "Banner-module--searchInput--cabcf";
export var searchbar = "Banner-module--searchbar--7fe55";
export var topSection = "Banner-module--topSection--c46a9";