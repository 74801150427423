import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import MainCard from "../common/new-blog/MainCard"
import * as styles from "./Future.module.scss"

const Future = ({ postsData, handleShowBlogs }) => {
  return (
    <div className={styles.future}>
      <Container>
        <h2 className={styles.heading}>
          Your Future <span className="text-primary">Guide 101</span>
        </h2>
        <Row className="gap-30 mt-5">
          {postsData?.map((post, i) => {
            return (
              <Col md={12} lg={6} key={i}>
                <MainCard
                  styleBody={{ maxWidth: "550px", minHeight: "208px" }}
                  styleCard={{ minHeight: "554px" }}
                  posts={post}
                />
              </Col>
            )
          })}
        </Row>
        <div className={styles.button}>
          <button onClick={handleShowBlogs} className="btn_white_border">
            Discover more
          </button>
        </div>
      </Container>
    </div>
  )
}

export default Future
