// extracted by mini-css-extract-plugin
export var Arrow1 = "BlogSlider-module--Arrow1--dc325";
export var Slide1 = "BlogSlider-module--Slide1--74cfa";
export var SlideHeading = "BlogSlider-module--SlideHeading--05290";
export var SlidePara = "BlogSlider-module--SlidePara--a0bbe";
export var SliderWidth = "BlogSlider-module--SliderWidth--5e983";
export var aiMl = "BlogSlider-module--aiMl--8f8e5";
export var authurName = "BlogSlider-module--authurName--ada70";
export var avatar = "BlogSlider-module--avatar--eb283";
export var iconContainer = "BlogSlider-module--iconContainer--e529d";
export var iconContainerLeft = "BlogSlider-module--iconContainerLeft--d2ccd";
export var portfolioArrowIcon = "BlogSlider-module--portfolioArrowIcon--62880";
export var portfolioArrowIconCover = "BlogSlider-module--portfolioArrowIconCover--31b1b";
export var portfolioArrowRightIconCover = "BlogSlider-module--portfolioArrowRightIconCover--0bca9";
export var readTime = "BlogSlider-module--readTime--70a44";
export var relative = "BlogSlider-module--relative--997ee";
export var slickSlide = "BlogSlider-module--slick-slide--cb96c";
export var sliderHead = "BlogSlider-module--sliderHead--46cab";
export var topSection = "BlogSlider-module--topSection--57ac3";