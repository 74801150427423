import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import SEORevamp from "../components/common/SEO_Revamp"
import BlogModal from "../components/common/new-blog/BlogModal"
import Banner from "../components/new-blog-page/Banner"
import BlogShow from "../components/new-blog-page/BlogShow"
import BlogSlider from "../components/new-blog-page/BlogSlider"
import Creative from "../components/new-blog-page/Creative"
import Future from "../components/new-blog-page/Future"
import Socials from "../components/new-blog-page/Socials"
import MainLayout from "../layouts/MainLayout"

const BlogTemplate = ({ pageContext }) => {
  const [topicSearch, setTopicSearch] = useState("")
  const [searching, setSearching] = useState(false)
  const [filterCatagory, setFilterCatagory] = useState({})

  useEffect(() => {
    let catagoryModel =
      filterCatagory?.selectedCategories &&
      Object.keys(filterCatagory?.selectedCategories)?.length > 0 &&
      Object.keys(filterCatagory?.selectedCategories)
    searching &&
      navigate(
        `/search/?${
          filterCatagory?.search ? `topic=${filterCatagory?.search}` : ""
        }${topicSearch ? `topic=${topicSearch}` : ""}${
          filterCatagory?.search && catagoryModel ? "&" : ""
        }${catagoryModel ? `categories=${catagoryModel}` : ""}`
      )
  }, [searching])

  const handleShowBlogs = () => {
    navigate("/blogs/")
  }

  return (
    <MainLayout bgChanged={false}>
      <Banner
        postsData={pageContext?.blogs}
        setTopicSearch={setTopicSearch}
        popularBlogs={pageContext?.popularBlogs}
        setSearching={setSearching}
      />
      <BlogShow
        categories={pageContext?.allCategories}
        postsData={pageContext?.blogs}
        handleShowBlogs={handleShowBlogs}
      />
      <BlogSlider posts={pageContext?.aiBlogs} />
      <Future
        postsData={[
          pageContext?.popularBlogs[
            Math.floor(Math.random() * pageContext.popularBlogs?.length)
          ],
          pageContext?.aiBlogs[
            Math.floor(Math.random() * pageContext?.aiBlogs?.length)
          ],
        ]}
        handleShowBlogs={handleShowBlogs}
      />
      <Socials />
      <BlogModal
        categories={pageContext?.allCategories}
        setFilterCatagory={setFilterCatagory}
        setSearching={setSearching}
        setTopicSearch={setTopicSearch}
      />
      <Creative />
    </MainLayout>
  )
}

export default BlogTemplate

export const Head = () => {
  return (
    <SEORevamp
      title="Read Tech Expert Opinions | InvoZone Blog"
      description="Read the InvoZone blog to catch up on interesting insights and expert opinions related to software development, the Internet and technology."
      image="https://invozone-backend.s3.amazonaws.com/Blogs_29bc5a670c.webp"
    />
  )
}
