import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./MainCard.module.scss"

const MainCard = ({ styleCard, styleBody, posts }) => {
  const image = getImage(posts?.featuredImage?.localFile)

  return (
    <div className={styles.Maincard} style={styleCard}>
      <div className={styles.image}>
        <Link to={`/blog/${posts?.slug}/`}>
          {image && (
            <GatsbyImage
              placeholder="blurred"
              decoding="async"
              loading="lazy"
              className={styles.imagebox}
              image={image}
              alt={posts?.title}
            />
          )}
        </Link>
      </div>
      <Link
        to={`/blog/category/${posts?.categories[0]?.slug}/`}
        className={styles.topSection}
      >
        {posts?.categories[0]?.name}
      </Link>
      <div className={styles.body} style={styleBody}>
        <div className={styles.border}></div>
        <h3>
          <Link to={`/blog/${posts?.slug}/`}>{posts?.title}</Link>
        </h3>
        <p>{posts?.seo?.metaDesc}</p>
        <div className={styles.authurName}>
          <Link to={`/author/${posts?.writer?.slug}/`}>
            <div className="align-items-center d-flex">
              <small className={styles.avatar}>{posts?.writer?.name}</small>

              <small className={`${styles.readTime} `}>
                {posts?.isWP
                  ? `${posts?.readingTime || 0} min read `
                  : posts?.readingTime}
              </small>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MainCard
