import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import Container from "react-bootstrap/Container"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import LeftArrow from "../../images/rightarrow1.svg"
import RightArrow from "../../images/rightSlide.svg"
import * as styles from "./BlogSlider.module.scss"
import "./BlogSlider.scss"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={RightArrow}
          onClick={onClick}
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={LeftArrow}
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

function Slide1({ posts }) {
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)
  const slide1 = useRef(null)

  useEffect(() => {
    ;(current === posts.length - 3 ||
      current_1 === posts.length - 2 ||
      current_2 === posts.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 2000)
  }, [current, current_1, current_2])

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    autoplay: true,
    autoplaySpeed: 2000,
    lazyLoad: "ondemand",
    beforeChange: (current, next) => setCurrent(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  return (
    <Container className={`${styles.Arrow1} Arrow2`}>
      <h2 className={styles.sliderHead}>
        New Era <span className="text-primary"> of Tech</span>
      </h2>

      <Slider {...settings} ref={slide1} className={styles.SliderWidth}>
        {posts?.slice(0, 8)?.map((item, index) => {
          return (
            <div className={styles.Slide1} key={index}>
              <GatsbyImage
                image={getImage(item?.featuredImage?.localFile)}
                alt={item?.title}
                decoding="async"
                loading="lazy"
                style={{
                  height: 238,
                  maxWidth: "100%",
                  objectPosition: "left",
                }}
              />
              <div className={styles.relative}>
                <Link to={`/blog/${item?.slug}/`}>
                  <h3 className={styles.SlideHeading}>{item?.title}</h3>
                </Link>
                <p className={styles.SlidePara}> {item?.seo?.metaDesc}</p>
                <div className={styles.aiMl}>
                  <Link
                    to="/blog/category/ai-ml/"
                    className={styles.topSection}
                  >
                    AI/ML
                  </Link>

                  <div className={styles.authurName}>
                    <Link
                      to={`/author/${item?.writer?.slug}/`}
                      className="align-items-center d-flex"
                    >
                      <small className={styles.avatar}>
                        {item?.writer?.name}
                      </small>

                      <small className={`${styles.readTime} `}>
                        {item?.isWP
                          ? `${item?.readingTime || 0} min read`
                          : item?.readingTime}
                      </small>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </Container>
  )
}

export default Slide1
