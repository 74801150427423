import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Row from "react-bootstrap/Row"
import { stringTruncate } from "../../utils"
import MainCard from "../common/new-blog/MainCard"
import * as styles from "./Banner.module.scss"

const Banner = ({ postsData, popularBlogs, setSearching, setTopicSearch }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const handleSearch = e => {
    if (searchTerm === "") return
    // navigate("#blogTopicId")
    e?.target.blur()
    setSearching(true)
    setTopicSearch(searchTerm)
    return
  }

  const changeHandler = e => {
    if (searchTerm && !e?.target?.value) {
      setTopicSearch("")
    }
    setSearchTerm(e?.target?.value || "")
  }

  return (
    <section className={styles.banner}>
      <Container>
        <h1 className={styles.heading}>
          Latest <span className="text-primary"> Posts</span>
        </h1>
        <Row className="gap-30 mt-5">
          <Col md={12} lg={8}>
            <MainCard
              styleBody={{ maxWidth: "658px", minHeight: "230px" }}
              styleCard={{ minHeight: "576px" }}
              posts={postsData[0]}
            />
          </Col>
          <Col md={12} lg={4}>
            <div className={styles.rightBox}>
              <div className={styles.searchbar}>
                <InputGroup className={styles.round}>
                  <FormControl
                    type="text"
                    aria-label="Search"
                    placeholder="Search Topics"
                    aria-describedby="Newsletter"
                    name="search"
                    value={searchTerm}
                    onChange={changeHandler}
                    onKeyPress={e => (e.key === "Enter" ? handleSearch(e) : "")}
                    className={styles.searchInput}
                  />
                  {searchTerm && (
                    <div className={styles.crossIcon}>
                      <div onClick={changeHandler}>x</div>
                    </div>
                  )}
                  <InputGroup.Append>
                    <Button
                      className={styles.btnRound}
                      type="submit"
                      aria-label="Search"
                      // disabled={!searchTerm}
                      onClick={handleSearch}
                    >
                      <StaticImage
                        decoding="async"
                        loading="lazy"
                        src={"../../images/new-blog/search.svg"}
                        alt="search"
                        width={19}
                        height={19}
                      />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
              <div className={styles.most}>
                <div className={styles.backgroundblur}></div>
                <div className={styles.headPop}>
                  <h2>
                    <span>Most</span>{" "}
                    <span className="text-primary"> Popular Posts</span>
                  </h2>
                </div>
                <div className={`${styles.popularSection} `}>
                  <div className={styles.popularContainer}>
                    {popularBlogs?.map(item => (
                      <div key={item?.slug} className={styles.popularItem}>
                        <Link
                          to={`/blog/${item?.slug}/`}
                          className={styles.popularImageBox}
                        >
                          <GatsbyImage
                            placeholder="blurred"
                            decoding="async"
                            loading="lazy"
                            image={getImage(item?.featuredImage?.localFile)}
                            className={styles.popularImage}
                            alt={item?.title}
                          />
                        </Link>

                        <Link
                          to={`/blog/${item?.slug}/`}
                          className={styles.info}
                        >
                          <p className={styles.innerText}>
                            {item?.categories[0]?.name}
                          </p>
                          <h3>{stringTruncate(item?.title, 37)}</h3>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
